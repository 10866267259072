<template xmlns="http://www.w3.org/1999/html">
  <base-dialog
    :width="'800px'"
    @cancel="cancel"
    @confirm="confirm"
  >
    <div class="add-api">
      <div class="add-api__form">
        <form-list
            :config="config"
            :formData="formData"
            width="550px"
        />
      </div>
      <div class="add-api__example">
        <div class="title">{{ $t('algorithmPlatformManage.detail2.title') }}</div>
        <form-list
            :config="exampleConfig"
            width="550px"
        >
          <template v-slot:name>
            <div>{{ formData.name }}</div>
          </template>
          <template v-slot:url>
            <div>{{ formData.url }}</div>
          </template>
          <template v-slot:requestMethod>
            <div>POST JSON</div>
          </template>
          <template v-slot:requestHeader>
            <div>{{$t('algorithmPlatformManage.detail2.requestHeaderContent')}}</div>
            <div style="font-size: 12px;color: red">{{$t('algorithmPlatformManage.detail2.requestHeaderTip')}}</div>
          </template>
          <template v-slot:requestBody>
            <div>{{$t('algorithmPlatformManage.detail2.requestBodyContent')}}</div>
            <div style="font-size: 12px;color: red">{{$t('algorithmPlatformManage.detail2.requestBodyTip')}}</div>
          </template>
          <template v-slot:responseBody>
            <div>{{$t('algorithmPlatformManage.detail2.responseBodyContent')}}</div>
          </template>
        </form-list>
      </div>
    </div>
  </base-dialog>
</template>

<script>

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:名称
        {
          label: this.$t('algorithmPlatformManage.detail2.name'),
          prop: 'name',
          hidden: false,
          rules: {
            noNull: true,
          }
        },
        // lang: api地址
        {
          label: this.$t('algorithmPlatformManage.detail2.address'),
          prop: 'url',
          hidden: false,
          rules: {
            noNull: true,
          }
        },
        {
          tag: 'el-select',
          label: this.$t('algorithmPlatformManage.detail2.algorithmType'),
          prop: 'recognitionAlgorithmType',
          hidden: false,
          rules: {
            noNull: true,
          },
          tagProps: {
            options:this.$dict.algorithmType().filter(item => item.value !== 'AI_STATION')
          }
        },
      ],
      exampleConfig: [
        // lang:名称
        {
          tag: 'slot-content',
          label: this.$t('algorithmPlatformManage.detail2.apiName'),
          prop: 'name',
        },
        // lang: api地址
        {
          tag: 'slot-content',
          label: this.$t('algorithmPlatformManage.detail2.requestAddress'),
          prop: 'url',
        },
        // lang: api地址
        {
          tag: 'slot-content',
          label: this.$t('algorithmPlatformManage.detail2.requestMethod'),
          prop: 'requestMethod',
        },
        {
          tag: 'slot-content',
          label: this.$t('algorithmPlatformManage.detail2.requestHeader'),
          prop: 'requestHeader',
        },
        {
          tag: 'slot-content',
          label: this.$t('algorithmPlatformManage.detail2.requestBody'),
          prop: 'requestBody',
        },
        {
          tag: 'slot-content',
          label: this.$t('algorithmPlatformManage.detail2.responseBody'),
          prop: 'responseBody',
        },
      ],
      enableObj: this.$util.listToObj(this.$dict.enable()),
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: [],
      workFlowStepTemplateTypeEnum: [],
      workFlowElementTypeEnum: [],
      workFlowElementFormTypeEnum: [],
      fileSrc: null,
      fileId: null,
      selectedType: []
    }
  },
  async mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      console.log('confirm', this.formData)
      console.log('cmd', this.cmd)
      const formData = this.$util.copyData(this.formData)
      console.log('formData', formData)
      switch (this.cmd) {
        case 'add_arithmetic_api':
          this.addMethod(formData)
          break
        case 'update':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.addArithmeticApi,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },
    editMethod(formData) {
      formData.id = this.tableRow.id
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.arithmeticPlatformUpdate,
        data: formData,
      }).then(() => {
        // lang:编辑成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'edit')
      })
    },

    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('algorithmPlatformManage.selectApi')
      this.dialogUrl =  '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dataCenterFindList
      this.dialogDefaultParams = {
        companyUid: this.$store.getters.company.uid,
        scope: 'CHANNEL_LINE',
        searchAll: 'YES'
      }
      this.dialogColumns = [
        // lang:外部API名称
        {
          label: this.$t('algorithmPlatformManage.apiName'),
          prop: 'name',
        },
        // lang:uid
        {
          label: 'ID',
          prop: 'id',
        },
      ]
      this.dialogConfig = [
      ]
      this.showDialogTableList = true
    },
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
      if (this.formData.recognitionAlgorithmType === 'EXTERNAL_AI_IMG') {
        this.config[this.config.length -1].hidden = false
        const data = {
          id: row.id
        }
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.switchExternalAiApiData,
          data,
        }).then((res) => {
          this.formData.recognitionLabelDataList = res
        })
      }
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
    addRecognitionType() {
      const data = {
        name: '',
        confidence: 0,
        value: '',
        type: 0
      }
      if(this.formData.recognitionLabelDataList && this.formData.recognitionLabelDataList instanceof Array) {
        this.formData.recognitionLabelDataList.push(data)
      } else {
        this.formData.recognitionLabelDataList = []
        this.formData.recognitionLabelDataList.push(data)
      }
    },
    delRecognitionType() {
      if(this.selectedType.length > 0) {
        this.formData.recognitionLabelDataList = this.formData.recognitionLabelDataList.filter((_, index) => this.selectedType.indexOf(index) === -1)
        this.selectedType = []
      }
    },
    onChange(val) {
      const isExists = this.selectedType.filter(i => i === val)
      if(isExists.length > 0) {
        this.selectedType = this.selectedType.filter(i => i !== val)
      } else {
        this.selectedType.push(val)
      }
      console.log(this.selectedType)
    }
  },
}
</script>
<style lang="scss" scoped>
//@import '@/style/theme.scss';
.add-api {
  display: flex;
  justify-content: space-between;
  &__form {
    padding: 20px;
    width: 50%;
  }
  &__example {
    padding: 20px;
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      margin-bottom: 10px;
      font-size: 24px;
      font-weight: bold;
    }
  }
}

</style>
