<template>
  <div>
    <dialog-form-list
      :config="config"
      :formData="formData"
      @cancel="cancel"
      @confirm="confirm"
      @openDialog="openDialog"
    >
      <template v-slot:recognitionLabelDataList>
        <div class="recognition-type">
          <el-row class="recognition-type__options">
            <el-col :span="12"><el-button @click="addRecognitionType" plain>{{$t('base.add')}}</el-button></el-col>
            <el-col :span="12"><el-button @click="delRecognitionType" plain>{{$t('base.del')}}</el-button></el-col>
          </el-row>
          <div class="recognition-type__list">
            <div v-for="(item, index) in formData.recognitionLabelDataList" class="item">
              <div class="item__left">
                <div class="field"><div>{{$t('recognitionAlgorithmManage.labelName')}}: </div><el-input v-model="item.name"/></div>
                <div class="field"><div>{{$t('recognitionAlgorithmManage.confidence')}}: </div><el-input type="number" v-model="item.confidence"/></div>
                <div class="field"><div>{{$t('recognitionAlgorithmManage.content')}}: </div><el-input v-model="item.value"/></div>
                <div class="field"><div>{{$t('recognitionAlgorithmManage.labelType')}}: </div>
                  <el-select v-model="item.type">
                    <el-option :value="0" :label="$t('recognitionAlgorithmManage.category')" />
                    <el-option :value="1" :label="$t('recognitionAlgorithmManage.read')" />
                  </el-select>
                </div>
              </div>
              <div class="item__right">
                <el-checkbox @change="onChange(index)" :value="selectedType.indexOf(index) !== -1"/>
              </div>
            </div>
          </div>
        </div>
      </template>
    </dialog-form-list>
    <!-- 弹出表格 -->
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :defaultParams="dialogDefaultParams"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
      :multiple="false"
    >
    </dialog-table-list>
  </div>
</template>

<script>

import { getSessionStorage, getLocalStorage} from '@/utils'

export default {
  props: {
    tableRow: {
      // 当前选中的表格行数据
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {},
      config: [
        // lang:名称 0
        {
          label: this.$t('recognitionAlgorithmManage.name'),
          prop: 'name',
          hidden: false,
          rules: {
            noNull: true,
          }
        },
        // lang:类型 1
        {
          tag: 'el-select',
          label: this.$t('recognitionAlgorithmManage.type'),
          prop: 'recognitionAlgorithmType',
          hidden: false,
          rules: {
            noNull: true,
          },
          tagProps: {
            options: [],
            // props: {
            //   label: 'value',
            //   value: 'id',
            // },
          },
          tagEvents: {
            change(val) {
              // if (val === 'API' || val === 'EXTERNAL_AI_IMG') {
              //   if(val === 'API') {
              //     self.config[2].label = self.$t('recognitionAlgorithmManage.externalApiName')
              //   } else if(val === 'EXTERNAL_AI_IMG') {
              //     self.config[2].label = self.$t('recognitionAlgorithmManage.externalAiName')
              //   }
              //   self.config[2].hidden = false
              //   self.config[2].rules.noNull = true
              // } else {
              //   self.config[2].hidden = true
              //   self.config[2].rules.noNull = false
              // }
              //
              if (val === 'EXTERNAL_AI_IMG' || val === 'EXTERNAL_AI_VIDEO') {
                self.config[2].hidden = false
                self.config[2].rules.noNull = true
              } else {
                self.config[2].hidden = true
                self.config[2].rules.noNull = false
              }

              // if (val === 'AI_STATION') {
              //   self.config[3].hidden = false
              //   self.config[3].rules.noNull = true
              // } else {
              //   self.config[3].hidden = true
              //   self.config[3].rules.noNull = false
              // }
            },
          }
        },
        // lang:外部API 2
        {
          tag: 'dialog-define',
          label: this.$t('recognitionAlgorithmManage.externalApiName'),
          prop: 'externalApiFieldId',
          hidden: true,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
        // lang: 算法平台 3
        {
          tag: 'dialog-define',
          label: this.$t('recognitionAlgorithmManage.algorithmPlatform'),
          prop: 'algorithmPlatformId',
          hidden: false,
          rules: {
            noNull: false,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
        // lang: 平台标识 4
        {
          tag: 'el-select',
          label: this.$t('recognitionAlgorithmManage.aiStationId'),
          prop: 'aiStationId',
          hidden: true,
          rules: {
            noNull: false,
          },
          tagProps: {
            options: null,
          },
          tagEvents: {
            change(val) {
              self.formData.aiStationName = self.config[4].tagProps.options.filter(item => item.value === val)[0].label
            }
          }
        },
        // lang: 平台标识 5
        {
          label: this.$t('recognitionAlgorithmManage.aiStationId'),
          prop: 'aiStationId',
          hidden: false,
          rules: {
            noNull: false,
          }
        },
        // lang:名称 6
        {
          label: this.$t('recognitionAlgorithmManage.externalAiName'),
          prop: 'aiStationName',
          hidden: false,
          rules: {
            noNull: false,
          }
        },
          // 7
        {
          tag: 'slot-content',
          label: this.$t('recognitionAlgorithmManage.recognitionType'),
          prop: 'recognitionLabelDataList',
          hidden: true,
          rules: {
            // noNull: true,
          },
        },
      ],
      enableObj: this.$util.listToObj(this.$dict.enable()),
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: [],
      workFlowStepTemplateTypeEnum: [],
      workFlowElementTypeEnum: [],
      workFlowElementFormTypeEnum: [],
      fileSrc: null,
      fileId: null,
      selectedType: []
    }
  },
  watch: {
    'formData.algorithmPlatformId'(val) {
      this.formData.aiStationId = null
      if(val) {
        this.config[5].hidden = true
        this.config[4].hidden = false
        this.config[6].hidden = true
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findArithmeticPlatformRecognitionList,
          data: {
            algorithmPlatformId: this.formData.algorithmPlatformId.id,
            subjectToken: getSessionStorage('token'),
          }
        }).then(res => {
          this.config[4].tagProps.options = JSON.parse(res).map(item => {
            return {
              label: item.name,
              value: item.aiStationId
            }
          })
        })
      } else {
        this.config[5].hidden = false
        this.config[4].hidden = true
        this.config[4].tagProps.options = null
        this.config[6].hidden = false
      }
    }
  },
  async mounted() {
    this.setDefaultValue()
    this.findRecognitionAlgorithmTypes()
  },
  methods: {
    findRecognitionAlgorithmTypes() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findRecognitionAlgorithmTypes,
        data: {}
      }).then(res => {
        // window.location.href = res
        console.log(res)
        const algorithmTypes = this.$dict.algorithmType()
        const options = []
        res.forEach(v => {
          const type = algorithmTypes.filter(i => i.value === v)
          if(type.length > 0) {
            options.push( type[0])
          } else {
            options.push({
              label: v,
              value: v
            })
          }
        })
        console.log('res', options)
        this.config[1].tagProps.options = algorithmTypes
      })
    },
    // 设置默认值
    setDefaultValue() {
      this.$nextTick(() => {
        //默认值
        if (this.cmd !== 'add') {
          this.$util.setDefaultValue(this.formData, this.tableRow)
          if(this.tableRow.externalApiField && (this.tableRow.recognitionAlgorithmType === 'EXTERNAL_AI_IMG' || this.tableRow.recognitionAlgorithmType === 'EXTERNAL_AI_VIDEO')) {
            this.config[2].hidden = false
            this.config[2].rules.noNull = true
            this.formData.externalApiFieldId = this.tableRow.externalApiField
          } else {
            this.config[2].hidden = true
            this.config[2].rules.noNull = false
          }
          if(this.tableRow.algorithmPlatform) {
            this.formData.algorithmPlatformId = this.tableRow.algorithmPlatform
          }
          this.$nextTick(() => {
            if(this.formData.algorithmPlatformId) {
              this.formData.aiStationId = parseInt(this.tableRow.aiStationId)
            } else {
              this.formData.aiStationId = this.tableRow.aiStationId
            }
          })
          if (this.tableRow.recognitionAlgorithmType === 'EXTERNAL_AI_IMG' || this.tableRow.recognitionAlgorithmType === 'EXTERNAL_AI_VIDEO') {
            if(this.tableRow.recognitionLabelDataList && this.tableRow.recognitionLabelDataList.length > 0) {
              this.formData.recognitionLabelDataList = this.tableRow.recognitionLabelDataList
              this.config[this.config.length -1].hidden = false
            } else {
              this.$ajax({
                url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findExternalAiApiData,
                data: {id: this.tableRow.id},
              }).then(res => {
                this.formData.recognitionLabelDataList = res
                this.config[this.config.length -1].hidden = false
              })
            }
          }
        }
      })
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      console.log('confirm', this.formData)
      console.log('cmd', this.cmd)
      const formData = this.$util.copyData(this.formData)
      if(formData.recognitionAlgorithmType === 'EXTERNAL_AI_VIDEO' || formData.recognitionAlgorithmType === 'EXTERNAL_AI_IMG') {
        formData.externalApiFieldId = formData.externalApiFieldId.id
      } else {
        formData.externalApiFieldId = null
      }
      // if(formData.recognitionAlgorithmType !== 'AI_STATION') {
      //   formData.aiStationId = ''
      // }
      if(formData.recognitionAlgorithmType === 'EXTERNAL_AI_IMG' || formData.recognitionAlgorithmType === 'EXTERNAL_AI_VIDEO') {
        if (!formData.recognitionLabelDataList || formData.recognitionLabelDataList.length === 0) {
          this.$element.showMsg(this.$t('recognitionAlgorithmManage.recognitionTypeTip'), 'error')
          return
        }
        let flag = false
        formData.recognitionLabelDataList.map(item => {
          if (!item.name || !item.confidence) {
            flag = true
          }
        })
        if (flag) {
          this.$element.showMsg(this.$t('recognitionAlgorithmManage.recognitionTypeInfoTip'), 'error')
          return
        }
      } else {
        formData.recognitionLabelDataList = null
      }
      if(formData.algorithmPlatformId) {
        formData.algorithmPlatformId = formData.algorithmPlatformId.id
      }
      switch (this.cmd) {
        case 'add':
          this.addMethod(formData)
          break
        case 'update':
          this.editMethod(formData)
          break
        default: // do something
      }
    },
    // 新增
    addMethod(formData) {
      console.log('formData', formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.arithmeticAdd,
        data: formData,
      }).then(() => {
        // lang:新增成功
        this.$element.showMsg(this.$t('base.addSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'add')
      })
    },
    editMethod(formData) {
      formData.id = this.tableRow.id
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.arithmeticUpdate,
        data: formData,
      }).then(() => {
        // lang:编辑成功
        this.$element.showMsg(this.$t('base.editSuccess'), 'success')
        this.$forceUpdate()
        this.$emit('closePage', 'edit')
      })
    },

    // 打开表格弹框
    openDialog(item) {
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      if (item.prop === 'aiStationId') {
        this.dialogTitle = this.$t('recognitionAlgorithmManage.selectPlatformAiStationId')
        this.dialogUrl = '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findArithmeticPlatformRecognitionList
        this.dialogDefaultParams = {
          algorithmPlatformId: this.formData.algorithmPlatformId.id,
          subjectToken: getSessionStorage('token'),
        }
        this.dialogColumns = [
          // lang:算法平台
          {
            label: this.$t('recognitionAlgorithmManage.aiStationId'),
            prop: 'aiStationId',
          },
          // lang:uid
          {
            label: this.$t('recognitionAlgorithmManage.aiStationIdName'),
            prop: 'name',
          },
        ]
        this.dialogConfig = []
      } else if (item.prop === 'algorithmPlatformId') {
        this.dialogTitle = this.$t('recognitionAlgorithmManage.selectPlatform')
        this.dialogUrl = '/' + this.$util.getLocalStorage('apiprefix') + this.$api.arithmeticPlatformFindArithmeticPlatforms
        this.dialogDefaultParams = {
          companyUid: this.$store.getters.company.uid,
        }
        this.dialogColumns = [
          // lang:算法平台
          {
            label: this.$t('recognitionAlgorithmManage.algorithmPlatform'),
            prop: 'name',
          },
          // lang:uid
          {
            label: 'ID',
            prop: 'id',
          },
        ]
        this.dialogConfig = []
      } else {
        this.dialogTitle = this.$t('recognitionAlgorithmManage.selectExternalApi')
        this.dialogUrl = '/' + this.$util.getLocalStorage('apiprefix') + this.$api.dataCenterFindList
        this.dialogDefaultParams = {
          companyUid: this.$store.getters.company.uid,
          scope: 'CHANNEL_LINE',
          searchAll: 'YES'
        }
        this.dialogColumns = [
          // lang:外部API名称
          {
            label: this.$t('recognitionAlgorithmManage.externalApiName'),
            prop: 'name',
          },
          // lang:uid
          {
            label: 'UID',
            prop: 'uid',
          },
        ]
        this.dialogConfig = []
      }

      this.showDialogTableList = true
    },
    // 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
      if ((this.formData.recognitionAlgorithmType === 'EXTERNAL_AI_IMG' || this.formData.recognitionAlgorithmType === 'EXTERNAL_AI_VIDEO') && this.targetProp === 'externalApiFieldId') {
        this.config[this.config.length -1].hidden = false
        const data = {
          id: row.id
        }
        this.$ajax({
          url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.switchExternalAiApiData,
          data,
        }).then((res) => {
          this.formData.recognitionLabelDataList = res
        })
      }
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.dialogTableList.searchList(dialogFormData)
    },
    addRecognitionType() {
      const data = {
        name: '',
        confidence: 0,
        value: '',
        type: 0
      }
      if(this.formData.recognitionLabelDataList && this.formData.recognitionLabelDataList instanceof Array) {
        this.formData.recognitionLabelDataList.push(data)
      } else {
        this.formData.recognitionLabelDataList = []
        this.formData.recognitionLabelDataList.push(data)
      }
    },
    delRecognitionType() {
      if(this.selectedType.length > 0) {
        this.formData.recognitionLabelDataList = this.formData.recognitionLabelDataList.filter((_, index) => this.selectedType.indexOf(index) === -1)
        this.selectedType = []
      }
    },
    onChange(val) {
      const isExists = this.selectedType.filter(i => i === val)
      if(isExists.length > 0) {
        this.selectedType = this.selectedType.filter(i => i !== val)
      } else {
        this.selectedType.push(val)
      }
      console.log(this.selectedType)
    }
  },
}
</script>
<style lang="scss" scoped>
@import '@/style/theme.scss';
.upload-no-img {
  width: 200px;
  height: 200px;
  font-size: 36px;
  line-height: 200px;
  text-align: center;
  border: 1px dashed #cccccc;
  cursor: pointer;
  border-radius: 4px;
}
:hover.upload-no-img {
  border: 1px dashed blue;
  color: blue;
}
.upload-has-img {
  width: 200px;
  height: 200px;
  line-height: 200px;
  cursor: pointer;
  border-radius: 4px;
  object-fit: contain;
  background: black;
}
.element-item {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 5px;
  &:last-child {
    margin-bottom: 0;
  }
  .field {
    width: 78%;
    border: 5px solid #eeeeee;
    background: #eeeeee;
    margin-right: 5px;
    .field-item {
      display: flex;
      flex-direction: row;
      margin-bottom: 5px;
      .el-input:first-child {
        margin-right: 5px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .opt {
    i {
      margin-right: 10px;
      cursor: pointer;
      color: $--color-primary;

      &:hover {
        font-weight: bold;
      }
    }
  }
}
.recognition-type {
  &__list {
    .item {
      margin-top: 10px;
      background: #efefef;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      &__left {
        .field {
          //display: flex;
          //align-items: center;
        }
      }
    }
  }
}
</style>
